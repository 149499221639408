var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('woot-button',{staticClass:"w-full",attrs:{"size":"expanded","color-scheme":"secondary","variant":"link"},on:{"click":function($event){return _vm.onClickOpenNotification()}}},[_c('div',{staticClass:"flex-row items-center p-2.5 leading-[1.4] border-b border-solid border-slate-50 dark:border-slate-700 flex w-full hover:bg-slate-75 dark:hover:bg-slate-900 hover:rounded-md"},[(!_vm.notificationItem.read_at)?_c('div',{staticClass:"w-2 h-2 rounded-full bg-woot-500"}):_c('div',{staticClass:"flex w-2"}),_vm._v(" "),_c('div',{staticClass:"flex-col ml-2.5 overflow-hidden w-full flex justify-between"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"font-bold text-slate-800 dark:text-slate-100"},[_vm._v("\n              "+_vm._s(`#${
                  _vm.notificationItem.primary_actor
                    ? _vm.notificationItem.primary_actor.id
                    : _vm.$t(`NOTIFICATIONS_PAGE.DELETE_TITLE`)
                }`)+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"text-xxs p-0.5 px-1 my-0 mx-2 bg-slate-50 dark:bg-slate-700 rounded-md"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  `NOTIFICATIONS_PAGE.TYPE_LABEL.${_vm.notificationItem.notification_type}`
                ))+"\n            ")])]),_vm._v(" "),(_vm.hasNotificationAssignee)?_c('div',[_c('thumbnail',{attrs:{"src":_vm.notificationAssigneeThumbnail,"size":"16px","username":_vm.notificationAssigneeName}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex w-full"},[_c('span',{staticClass:"overflow-hidden font-normal text-slate-700 dark:text-slate-200 whitespace-nowrap text-ellipsis"},[_vm._v("\n            "+_vm._s(_vm.notificationItem.push_message_title)+"\n          ")])]),_vm._v(" "),_c('span',{staticClass:"flex mt-1 font-semibold text-slate-500 dark:text-slate-400 text-xxs"},[_vm._v("\n          "+_vm._s(_vm.dynamicTime(_vm.notificationItem.last_activity_at))+"\n        ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }